import React from "react";
import styled from "styled-components";
// import { saturate, lighten } from "polished";
import {Container, Row, Col} from "react-bootstrap";

import {Title, Button, Section, Box, Text} from "../../components/Core";

import {device} from "../../utils";
import imgHeroTab from "../../assets/image/png/fisherman-ipad.png";

const SectionStyled = styled(Section)`
  background-color: ${({theme}) => theme.colors.dark}
`;

const ImgRight = styled(Box)`
  position: relative;
  left: -100px;
  @media ${device.md} {
    position: absolute;
    top: 0;
    max-width: 100%;
    min-width: 100%;
    right: 0;
    left: auto;
    transform: translateX(25%);
  }
`;

const Hero = () => {
    return (
        <>
            {/* <!-- Hero Area --> */}
            <SectionStyled className="position-relative">
                <div className="pt-5"></div>
                <Container>
                    <Row className="justify-content-center align-items-center position-relative">
                        <Col md="5" className="order-md-2 position-static">
                            <ImgRight>
                                <img
                                    src={imgHeroTab}
                                    alt=""
                                    className="img-fluid"
                                    data-aos="fade-left"
                                    data-aos-duration="750"
                                    data-aos-delay="1000"
                                    data-aos-once="true"
                                />
                            </ImgRight>
                        </Col>
                        <Col md="7" className="order-md-1">
                            <div
                                data-aos="fade-right"
                                data-aos-duration="750"
                                data-aos-delay="500"
                                data-aos-once="true"
                            >
                                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                                    <Title variant="hero" color="light">
                                        Developer's productivity platform.
                                        {/*<br className="d-none d-sm-block" />*/}
                                    </Title>
                                    <Text mb={4} color="light" opacity={0.7}>
                                        Understand the performance of your development team and enhance their speed by
                                        accessing key metrics in their day to day work.
                                    </Text>
                                    <div className="d-flex flex-column align-items-start pt-3">
                                        <a href={"https://app.fisherman.dev"} target={"_blank"}
                                           rel="noreferrer noopener">
                                            <Button mb={2}>Get started FREE</Button>
                                        </a>
                                        <Text
                                            variant="small"si
                                            fontSize={"14px"}
                                            color="light"
                                            opacity={0.7}
                                        >
                                            No credit card required
                                        </Text>
                                    </div>
                                </Box>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SectionStyled>
        </>
    );
};

export default Hero;
